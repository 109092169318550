
import { defineComponent, useStore, computed, onMounted } from '@nuxtjs/composition-api'
import { recommendationTemplateType } from '@/store/recommendations'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup() {
    const store = useStore()

    const mapComponentsToTemplateTypes = {
      [recommendationTemplateType.START]: 'OrganismRecommendationStartPage',
      [recommendationTemplateType.QUESTIONNAIRE]: 'OrganismRecommendationQuestionnaire',
      [recommendationTemplateType.SUGGESTIONS]: 'OrganismRecommendationSuggestionPage',
    }

    onMounted(() => {
      if (store.state.recommendations.activeTemplate === null && store.state.recommendations.selectionPath.length === 0) {
        store.commit('recommendations/setActiveTemplate', recommendationTemplateType.START)
      }
    })

    return {
      currentComponent: computed(() => mapComponentsToTemplateTypes[store.getters['recommendations/activeTemplate']]),
    }
  },
})
